<style scoped lang="scss">
  /deep/ .comp-type-edit {
    max-width: 450px;
  }
</style>

<template>
    <div>
        <el-dialog :title="titlePrev + '代理商'" custom-class="c-el-dialog comp-type-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="代理商名" prop="name">
                            <el-input v-model="dialogData.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="联系人" prop="contactName">
                            <el-input v-model="dialogData.contactName" placeholder="请输入联系人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="联系电话" prop="contactTel">
                            <el-input v-model="dialogData.contactTel" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="分红比例" prop="divideRate">
                            <el-input type="number" :min="0" :step="0.1" v-model="dialogData.divideRate" placeholder="请输入分红比例" size="small"></el-input>
                            <div class="red">请设置在0~1区间的值</div>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :xs="24">
                        <el-form-item label="关联会员">
                            <el-select placeholder="请搜索会员" v-model="dialogData.managerId" size="small" remote clearable filterable reserve-keyword
                                :remote-method="renderMemberOptions">
                                <el-option v-for="item in memberItems" :key="item.id" :label="`${item.name}【${item.id}】`" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>-->
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funAgent from "@/api/agent/index"
import * as funMember from "@/api/member/index"
export default {
    name: "compAgentEdit",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            titlePrev: "",
            dialogType: "",
            defaultData: {
                id: 0,
                name: "",
                contactTel: "",
                contactName: "",
                divideRate: null,
                // managerId: null
            },
            dialogData: {},
            formRefName: "refAgentEditForm",
            formRules: {
                name: [{ required: true, message: "请输入代理商名称", trigger: "blur" }],
                contactTel: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
                contactName: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
                divideRate: [{ required: true, message: "请输入分成比例", trigger: "blur" }]
            },
            memberItems: []
        }
    },
    created() {
        this.renderMemberOptions("")
    },
    methods: {
        show(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogType = row ? this.createOrUpdate[1] : this.createOrUpdate[0]
            if (this.dialogType == this.createOrUpdate[0]) {
                // 新增
                this.titlePrev = "新增"
                this.showDialog = true
            } else {
                // 修改
                this.titlePrev = "修改"
                this.dialogData.id = row.id
                window.$common.fullLoading()
                funAgent.GetAgentInfo({ id: this.dialogData.id }).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        this.dialogData[i] = res[i]
                    }
                    // this.dialogData.managerId = res.manager && res.manager.id || null
                    this.showDialog = true
                })
            }
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    funAgent.AgentCreateOrUpdate(commitData).then(() => {
                        this.commonSuccess()
                    })
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        async renderMemberOptions(query) {
            await funMember.QueryMemberList({ 
                page: 1,
                size: 10,
                keywords: query
            }).then(res => {
                this.memberItems = res.items
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("confirm", true)
        }
    }
}
</script>