import request from '@/utils/request'

// 获取会员列表
export function QueryMemberList(data) {
    return request({
        url: '/api/services/app/Member/QueryPagedList',
        method: 'post',
        data
    })
}

// 启用/禁用
export function SetActive(data) {
    return request({
        url: '/api/services/app/Member/SetActive',
        method: 'post',
        data
    })
}

// 为用户添加金额
export function AddAmount(data) {
    return request({
        url: '/api/services/app/Member/AddAmount',
        method: 'post',
        data
    })
}

// 更新会员统计信息及标签
export function UpdateStatistics(data) {
    return request({
      url: '/api/services/app/Member/UpdateStatistics',
      method: 'put',
      data
    })
}

// 删除会员缓存
export function RemoveCache(data) {
    return request({
      url: '/api/services/app/Member/RemoveCache',
      method: 'delete',
      params: data
    })
}

// 获取会员信息
export function GetMemberInfo(data) {
    return request({
      url: '/api/services/app/Member/GetMemberInfo',
      method: 'get',
      params: data
    })
}

// 获取会员的余额变更记录
export function QueryBalanceChangeLogs(data) {
    return request({
        url: '/api/services/app/Member/QueryBalanceChangeLogs',
        method: 'post',
        data
    })
}