<style lang="scss" scoped>
.page-agent-list {}
</style>

<template>
    <div class="page-agent-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()" size="medium">新增代理</el-button>
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange"
                        size="medium" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        @change="_search" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="代理ID/名称/手机号" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange">
                <el-table-column prop="id" label="ID" width="150"></el-table-column>
                <el-table-column prop="name" label="代理名称"></el-table-column>
                <el-table-column prop="contactName" label="联系人"></el-table-column>
                <el-table-column prop="contactTel" label="联系电话"></el-table-column>
                <el-table-column prop="divideRate" label="分成比例"></el-table-column>
                <el-table-column prop="deviceCount" label="设备数量"></el-table-column>
                <el-table-column prop="merchantCount" label="商家数量"></el-table-column>
                <el-table-column prop="creationTime" label="创建时间" sortable="custom" width="160"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_query(scope.row)">查看详情</el-dropdown-item>
                                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
                                <el-dropdown-item @click.native="computeStatistics(scope.row.id)">更新统计</el-dropdown-item>
                                <el-dropdown-item @click.native="skipToRoute(`/device/list?agentId=${scope.row.id}`)">查看设备</el-dropdown-item>
                                <el-dropdown-item @click.native="skipToRoute(`/member/list?id=${scope.row.id}`)">查看会员信息</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--详情-->
        <detail :ref="refDetail"></detail>
        <!--编辑-->
        <edit :ref="refEdit" @confirm="getDataList"></edit>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funCommon from "@/api/all"
import * as funAgent from "@/api/agent/index"
import moment from "moment"
import edit from "./components/AgentEditDialog"
import detail from "./components/detail"
export default {
    name: "pageAgentList",
    mixins: [enumConfigs],
    components: { detail, edit },
    data() {
        const that = this
        return {
            refDetail: "refAgentListToDetail",
            refEdit: "refAgentListToEdit",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: "",
                level: "",
                startDate: null,
                endDate: null,
                sortField: null,
                sortDirection: 0
            },
            categories: [],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    created() {
        if (this.$route.query.time === "0") {
            this.getTime(0)
        } else if (this.$route.query.time === "1") {
            this.getTime(1)
        } else if (this.$route.query.time === "2") {
            this.getTime(6)
        } else if (this.$route.query.time === "3") {
            this.getTime(29)
        }
        this.renderCategory()
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.filter.startDate = timeArr[0] || ""
            this.filter.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.filter.startDate = psTime
            this.filter.endDate = peTime
            this.selectedTime = [this.filter.startDate, this.filter.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funAgent.GetAgentList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
        // 处理传过来的时间数字
        getTime(days) {
            if (days == 1) {
                this.filter.startDate = moment().subtract(days, "days").format("YYYY-MM-DD 00:00:00")
                this.filter.endDate = moment().subtract(days, "days").format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.filter.startDate, this.filter.endDate]
            } else {
                this.filter.startDate = moment().subtract(days, "days").format("YYYY-MM-DD 00:00:00")
                this.filter.endDate = moment().format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.filter.startDate, this.filter.endDate]
            }
        },
        async renderCategory() {
            funCommon.CategoryAll({ type: 2 }).then(res => {
                this.categories = res
            })
        },
        _query(row) {
            this.$refs[this.refDetail].show(row)
        },
        _edit(row) {
            this.$refs[this.refEdit].show(row)
        },
        // 删除代理
        async _delete(row) {
            this.confirm(`确认要删除代理【${row.name}】吗？`).then(() => {
                window.$common.fullLoading()
                funAgent.remove({ ids: row.id }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        async computeStatistics(id) {
            window.$common.fullLoading()
            funAgent.ComputeAgentStatisticsInfo({ id }).then(() => {
                window.$common.closeFullLoading()
                this.getDataList()
            })
        },
        async sortChange(e) {
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this.getDataList()
        }
    }
}
</script>